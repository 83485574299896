import * as React from "react";
import * as ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import App from "./App";

import "./bootstrap.css";
import "./index.css";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCeiZoQsRd5Eau-9Jh9xor2JpLiF5ZYuQQ",
  authDomain: "journal-420.firebaseapp.com",
  projectId: "journal-420",
  storageBucket: "journal-420.appspot.com",
  messagingSenderId: "367783515112",
  appId: "1:367783515112:web:290b1aebce1a2c1adaca5d",
  measurementId: "G-4R4VM59BN0",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);
