import * as React from "react";
import { Routes, Route, Link, useParams, useNavigate } from "react-router-dom";
import Editor from "rich-markdown-editor";
import { collection, doc, getFirestore } from "firebase/firestore";
import * as EntryWriter from "./entryWriter";

import "./sidemenu.css";
import * as EditorTheme from "./editorTheme";

import { Row, Col } from "react-bootstrap";

export default function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:entryId" element={<Home />} />
        <Route path="about" element={<About />} />
      </Routes>
    </div>
  );
}

function Home() {
  const editorRef = React.useRef(null);
  const titleRef = React.useRef(null);

  const [entry, setEntry] = React.useState();
  const [menuKey, setMenuKey] = React.useState("1");
  const [editorKey, setEditorKey] = React.useState("1");

  const [darkMode, setDarkMode] = React.useState(
    window.matchMedia("(prefers-color-scheme: dark)").matches
  );

  const navigate = useNavigate();

  let { entryId } = useParams();

  const db = getFirestore();

  const theme = darkMode ? EditorTheme.dark : EditorTheme.light;

  React.useEffect(() => {
    document.body.style = `background: ${theme.background};`;
  }, [darkMode]);

  React.useEffect(() => {
    if (titleRef.current) {
      titleRef.current.style.height = "inherit";
      titleRef.current.style.height = `${titleRef.current.scrollHeight}px`;
    }
  }, [entry]);

  React.useEffect(() => {
    if (!entryId) {
      let entries = EntryWriter.fetchEntries();
      if (entries.length > 0) {
        const firstDoc = entries[0];
        navigate(`/${firstDoc.id}`, { replace: true });
      } else {
        const entryRef = doc(collection(db, "entries"));
        EntryWriter.createNewEntry(entryRef.id, null);
        navigate(`/${entryRef.id}`, { replace: true });
        setMenuKey(entryRef.id);
      }
    }
    setEntry(EntryWriter.load(entryId));
    setEditorKey(entryId);
  }, [entryId]);

  if (document && entry) {
    document.title = entry.title;
  }

  React.useEffect(() => {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (e) =>
        setDarkMode(e.matches ? "dark" : "light")
      );
  }, []);

  return (
    <>
      <main
        style={{
          background: theme.background,
          minHeight: "100vh",
        }}
      >
        <div>
          <div
            className="d-none d-lg-block"
            style={{ width: "320px", position: "fixed" }}
          >
            <FilesMenu
              key={menuKey}
              selectedDocId={entryId}
              theme={theme}
              darkMode={darkMode}
              toggleDarkMode={() => {
                setDarkMode(!darkMode);
              }}
            />
          </div>
          <Row style={{ margin: 0 }}>
            <Col style={{ flex: "0 0 320px" }} className="d-none d-lg-block" />
            <Col
              style={{
                maxWidth: 800,
                margin: "0 auto",
              }}
            >
              <div style={{ padding: 8 }}>
                {entry && (
                  <>
                    <div style={{ marginTop: 48 }}>
                      <textarea
                        rows={1}
                        ref={titleRef}
                        className="title"
                        style={{
                          outline: "none",
                          border: "none",
                          fontWeight: 800,
                          lineHeight: 1.5,
                          margin: 0,
                          background: "transparent",
                          padding: 0,
                          color: theme.text,
                          width: "100%",
                          resize: "none",
                          overflow: "hidden",
                          height: "inherit",
                        }}
                        value={entry.title}
                        defaultValue={entry.title}
                        placeholder="Title..."
                        onChange={(e) => {
                          EntryWriter.saveTitle(entry, e.target.value);
                          e.target.style.height = "inherit";
                          e.target.style.height = `${e.target.scrollHeight}px`;

                          setMenuKey(entry.title);
                        }}
                      />
                    </div>
                    <Editor
                      theme={theme}
                      key={editorKey}
                      ref={editorRef}
                      autoFocus
                      defaultValue={entry.body}
                      dark={darkMode}
                      uploadImage={async (file) => {
                        const result = await EntryWriter.uploadImage(file);
                        return result;
                      }}
                      onChange={(value) => {
                        EntryWriter.saveBody(entry, value());
                      }}
                    />
                  </>
                )}
              </div>
            </Col>
            <Col lg={2} className="d-none d-xl-block"></Col>
          </Row>
        </div>
      </main>
      <nav></nav>
    </>
  );
}

function About() {
  const [darkMode, setDarkMode] = React.useState(
    window.matchMedia("(prefers-color-scheme: dark)").matches
  );
  const theme = darkMode ? EditorTheme.dark : EditorTheme.light;

  React.useEffect(() => {
    document.body.style = `background: ${theme.background};`;
  }, [darkMode]);

  const aboutText = `## **Why did I create this**
  
  1. Lots of writing apps become publishing apps then no longer becomes fun to write
  2. I wanted something simple
  3. I was bored and wanted to work on a sideproject
 
 ## **Design philosophies**
 
  1. Local storage, cloud storage not required
  2. Should be easy to export your data
  3. Should make it easy to write

 ## **Warnings**
 
  1. Local storage on Chrome is only 5MB. Don't use too many images for now
 
 ## **Coming in the next version (1.1)**
  - [x] Works on mobile web optimized
  - [x] Dark mode
  - [x] Upload images
  - [ ] Can delete
  - [ ] Save to cloud, if you want
  - [ ] "Publish" posts
  - [ ] Export your entries
 `;

  return (
    <>
      <main>
        <div>
          <div
            className="d-none d-lg-block"
            style={{ width: "320px", position: "fixed" }}
          ></div>
          <Row style={{ margin: 0 }}>
            <Col style={{ flex: "0 0 210px" }} className="d-none d-lg-block" />
            <Col
              style={{
                maxWidth: 800,
                margin: "0 auto",
              }}
            >
              <div style={{ padding: 8 }}>
                <div>
                  <p
                    className="title"
                    style={{
                      outline: "none",
                      border: "none",
                      fontWeight: 800,
                      lineHeight: 2,
                      margin: 0,
                      padding: 0,
                      color: theme.text,
                    }}
                  >
                    Just Journal v1.0
                  </p>
                </div>
                <Editor
                  theme={theme}
                  autoFocus
                  readOnly={true}
                  defaultValue={aboutText}
                  darkMode={darkMode}
                />
              </div>
            </Col>
            <Col lg={1} className="d-none d-xl-block"></Col>
          </Row>
        </div>
      </main>
      <nav></nav>
    </>
  );
}

function FilesMenu({ selectedDocId, theme, darkMode, toggleDarkMode }) {
  const [entries, setEntries] = React.useState();
  const db = getFirestore();

  function createNewEntry() {
    const entryRef = doc(collection(db, "entries"));
    EntryWriter.createNewEntry(entryRef.id, null);
    fetchEntries();
  }

  function fetchEntries() {
    setEntries(EntryWriter.fetchEntries().reverse());
  }

  React.useEffect(() => {
    fetchEntries();
  }, []);

  const sideBarBackground = darkMode ? "#222" : "#F9F9F9";

  return (
    <>
      <div style={{ background: sideBarBackground, height: "100vh" }}>
        <div style={{ padding: 24 }}>
          <Row style={{ margin: 0 }}>
            <Col
              className="flex-grow-1"
              style={{
                fontSize: 18,
                fontWeight: 800,
                marginBottom: 16,
                marginTop: 8,
                color: theme.text,
              }}
            >
              Just Journal
            </Col>
            <Col>
              <Link to={"/about"}>
                <div
                  style={{
                    marginTop: 8,
                    textAlign: "right",
                    color: theme.text,
                  }}
                >
                  About v1
                </div>
              </Link>
            </Col>
          </Row>

          <div
            style={{
              background: "#de9c6e",
              color: "white",
              padding: 14,
              textAlign: "center",
              cursor: "pointer",
              borderRadius: 2,
              fontWeight: 600,
            }}
            onClick={() => {
              createNewEntry();
            }}
          >
            New Entry
          </div>
        </div>

        <Divider theme={theme} />

        <div
          style={{
            maxHeight: "100%",
            overflow: "scroll",
            paddingBottom: "2000px !important",
          }}
        >
          {entries &&
            entries.map((e, i) => {
              return (
                <Link className={darkMode ? "dark" : ""} key={i} to={`/${e.id}`}>
                  <div
                    className={"menu-item"}
                    style={{
                      padding: 24,
                      paddingTop: 12,
                      paddingBottom: 12,
                      fontWeight: 500,
                      cursor: "pointer",
                      color: theme.text,
                      background: (e.id === selectedDocId ? "active" : "")
                        ? theme.codeBorder
                        : "",
                    }}
                  >
                    {e.title.length > 0 ? e.title : "Untitled"}
                  </div>
                </Link>
              );
            })}
        </div>

        <div
          style={{
            color: theme.code,
            position: "absolute",
            bottom: 0,
            paddingLeft: 24,
            background: sideBarBackground,
            paddingTop: 16,
            paddingBottom: 16,
            width: "296px",
            borderTop: `1px solid ${theme.codeBorder}`,
            cursor: "pointer",
            fontSize: 12,
            fontWeight: 500,
          }}
          onClick={() => {
            toggleDarkMode();
          }}
        >
          {`Toggle ${darkMode ? "light" : "dark"} mode`}
        </div>
      </div>
    </>
  );
}

function Divider({ theme }) {
  return (
    <div
      style={{ height: 1, width: "100%", background: theme.codeBorder }}
    ></div>
  );
}
