import moment from "moment";

export function load(key) {
  if (!key) {
    return;
  }
  const data = localStorage.getItem(key);
  try {
    return JSON.parse(data);
  } catch (e) {
    // return createNewDocument();
  }
}

export function createNewEntry(entryId, title) {
  let entries = fetchEntries();
  let newEntry = {
    id: entryId,
    title: title ?? moment(new Date()).format("MMMM Do YYYY"),
  };
  entries.push(newEntry);
  localStorage.setItem(entryId, JSON.stringify(newEntry));
  localStorage.setItem("entries", JSON.stringify(entries));

  return { newEntry };
}

export function fetchEntries() {
  let entries = localStorage.getItem("entries") ?? "[]";
  entries = JSON.parse(entries);
  return entries;
}

export function saveBody(entry, value) {
  entry.body = value;
  localStorage.setItem(entry.id, JSON.stringify(entry));
}

export function saveTitle(entry, value) {
  entry.title = value;
  localStorage.setItem(entry.id, JSON.stringify(entry));
  let entries = fetchEntries();
  entries = entries.map((e) => {
    if (e.id === entry.id) {
      return { id: entry.id, title: entry.title };
    } else {
      return e;
    }
  });

  localStorage.setItem("entries", JSON.stringify(entries));
}

export async function uploadImage(file) {
  let imageData = await toBase64(file);
  return imageData;
}

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
